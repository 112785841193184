var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"container-rda"},[_c('b-row',[_c('b-col',[_c('b-breadcrumb',{attrs:{"items":_vm.breadcrumb}})],1)],1),_c('b-row',{staticClass:"mb-4"},[_c('b-col',[_c('h2',{staticClass:"purple bold"},[_vm._v("Visualizar registro")])]),_c('b-col',{staticClass:"text-right"})],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[(_vm.student)?_c('StandardInput',{attrs:{"type":"text","label":"Aluno","disabled":""},model:{value:(_vm.student.User.name),callback:function ($$v) {_vm.$set(_vm.student.User, "name", $$v)},expression:"student.User.name"}}):_vm._e()],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"calendar"},[_c('label',[_vm._v("Data")]),_c('Datepicker',{attrs:{"placeholder":"Selecione","format":"dd/MM/yyyy","use-utc":true,"language":_vm.languages[_vm.language],"disabled":""},on:{"input":function($event){_vm.dateChanged = true}},model:{value:(_vm.dateAux),callback:function ($$v) {_vm.dateAux=$$v},expression:"dateAux"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('StandardInput',{attrs:{"type":"time","name":"designation","label":"Hora","disabled":""},model:{value:(_vm.timeAux),callback:function ($$v) {_vm.timeAux=$$v},expression:"timeAux"}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('StandardInput',{attrs:{"type":"text","label":"Tipo do registro","disabled":""},model:{value:(_vm.attendance.origin),callback:function ($$v) {_vm.$set(_vm.attendance, "origin", $$v)},expression:"attendance.origin"}})],1),_c('b-col',{attrs:{"md":"3"}},[(
          _vm.attendance.origin === 'Aplicativo' &&
          _vm.attendance.token_user &&
          _vm.attendance.token_user.name
        )?_c('StandardInput',{attrs:{"type":"text","label":"Token gerado por","disabled":""},model:{value:(_vm.attendance.token_user.name),callback:function ($$v) {_vm.$set(_vm.attendance.token_user, "name", $$v)},expression:"attendance.token_user.name"}}):_vm._e()],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"group-radio",attrs:{"disabled":""}},[_c('label',{staticClass:"mb-4",attrs:{"for":"label"}},[_vm._v("Ação")]),_c('div',{staticClass:"radio-inline"},[_c('b-form-radio',{attrs:{"name":"options","value":"presence"},model:{value:(_vm.attendance.type),callback:function ($$v) {_vm.$set(_vm.attendance, "type", $$v)},expression:"attendance.type"}},[_vm._v(" Presença ")]),_c('b-form-radio',{attrs:{"name":"options","value":"regular_leave"},model:{value:(_vm.attendance.type),callback:function ($$v) {_vm.$set(_vm.attendance, "type", $$v)},expression:"attendance.type"}},[_vm._v(" Saída regular ")]),_c('b-form-radio',{attrs:{"name":"options","value":"absence"},model:{value:(_vm.attendance.type),callback:function ($$v) {_vm.$set(_vm.attendance, "type", $$v)},expression:"attendance.type"}},[_vm._v(" Falta ")]),_c('b-form-radio',{attrs:{"name":"options","value":"late"},model:{value:(_vm.attendance.type),callback:function ($$v) {_vm.$set(_vm.attendance, "type", $$v)},expression:"attendance.type"}},[_vm._v(" Chegada tardia ")]),_c('b-form-radio',{attrs:{"name":"options","value":"antecipated"},model:{value:(_vm.attendance.type),callback:function ($$v) {_vm.$set(_vm.attendance, "type", $$v)},expression:"attendance.type"}},[_vm._v(" Saída antecipada ")])],1)])],1),_c('div',{staticClass:"w-100 mt-5"})],1),_c('b-row',[_c('div',{staticClass:"w-100 mt-5"}),_c('b-col',{attrs:{"md":"2"}},[_c('b-button',{staticClass:"large blue outline",attrs:{"to":{ name: _vm.redirectToCorrectUrl('faltas-presencas') }}},[_vm._v("Voltar")])],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-button',{staticClass:"blue outline",attrs:{"to":{
          name: _vm.redirectToCorrectUrl('editar-faltas-presencas'),
          params: {
            id: _vm.attendance.id,
          },
        }}},[_vm._v("Editar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }